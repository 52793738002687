export const MOVE_FOCUS = !window.location.pathname.startsWith(
  '/antifraud/plugin/log/'
)
  ? 'Opened a new window or tab; Minimized the browser; Opened other program;'
  : 'Moved focus away from testing window';
export const MULTIPLE_CAMS = 'Multiple cameras detected';
export const RESET_MULTIPLE_FACES = 'Reset multiple faces detected';
export const RESET_NO_FACE_DETECTED = 'Reset no face detected';
export const MULTIPLE_DISPLAYS = 'Multiple screens detected';
export const MULTIPLE_FACES = 'Multiple people detected on camera';
export const NEW_TAB = 'User attempted to open new tabs and/or new windows';
export const MULTIPLE_FACES_DETECTED = 'Multiple people detected on camera';
export const NO_FACE_DETECTED = 'No user detected';
export const NEW_TAB_WINDOW =
  'User attempted to open new tabs and/or new windows';
export const NEW_ANON_TAB_WINDOW =
  'User attempted to open new incognito tabs and/or new windows';
export const START_STREAMING = 'Video streaming started';
export const STOP_STREAMING = 'Video streaming stopped';
export const MORE_THAN_ONE_CAM = 'Multiple cameras detected';
export const MULTIPLE_DISPLAYS_DETECTED = 'Multiple screens detected';
export const CLOSED_WINDOW =
  'Closed browser or tab/refreshed the page via browser';
export const CLOSED_WINDOW_OR_TAB =
  'Closed browser or tab/refreshed the page via keyboard';
export const FINISH_SIMULATION = 'Exam completed successfully';
export const START_SIMULATION = 'Exam started';
export const TEST_PAUSED = 'Exam paused';
export const TEST_RESUMED = 'Exam resumed';
export const OFFLINE = 'Lost connection';
export const ONLINE = 'Connection established';
export const ONLINE_SERVER = 'Connection established with server';
export const OFFLINE_SERVER = 'Connection lost with server';
export const SIMULATION_TIMEOUT = 'Simulation Timed Out';
export const SIMULATION_EXPIRED = 'Simulation Expired';
export const REFRESH_PAGE = 'Refreshed the page via browser';
export const ROOMSCAN_RESUMED = 'Room Scan restarted';
export const ROOMSCAN_FAILED_MORE_THAN_TWICE_AND_RESUMED =
  '2 or more errors with Room Scan, Room Scan resumed';
export const ROOMSCAN_STARTED = 'Random room scan started';
export const ROOMSCAN_LOCKED = 'User offline when Room Scan started';
export const ROOMSCAN_UNLOCKED = 'User reconnected, Room Scan can start again';
export const SUSPENDED_CAMERA = 'User/some error suspended the camera';
export const GAZE_DETECTION =
  'User looked away from screen for at least 3 seconds';
export const COUNTDOWN_INFO =
  'User was offline for more than 3 minutes and timed out';
export const SESSION_FAILED_INFO = 'Session not saved properly';
export const ERROR_CHOOSE_WINDOW = 'Error with Share Screen';
export const ERROR_FINISH_SESSION = 'Error finalizing session';
export const ERROR_APPLY_PHOTOS = 'Error with photos';
export const ERROR_APPLY_VIDEO = 'Error with video';
export const OPEN_DEVTOOLS = 'Opened the dev tools';
